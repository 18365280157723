import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

function NavTabs({
    section
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let token = useParams().token;
    return (
        <Nav
            activeKey={section}
            defaultActiveKey={section}
            variant="tabs"
            onSelect={(selectedKey) => navigate(`/${token}/${selectedKey}`)
            }
        >
            <Nav.Item>
                <Nav.Link eventKey="account" >{t('Cuenta')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="orders" >{t('Ordenes')}</Nav.Link>
            </Nav.Item>

        </Nav >
    );
}


export default NavTabs;