import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Spinner,
    Container,
} from 'react-bootstrap';
import Header from '@Components/Header';
import AccountAddress from '@Components/AccountAddress';
import {
    useParams,
} from "react-router-dom";

import utils from '@Components/utils';

import { useCookies } from 'react-cookie';
import { useTranslation, } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import NavTabs from '@Components/NavTabs';


function CustomerInfo() {
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    let token = useParams().token;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        fetching
    } = dataReducer;



    React.useEffect(() => {
        dispatch({
            type: 'ACCOUNT_FETCH',
            payload: {
                token,
            }
        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (account?.account_id) {

            dispatch({
                type: 'ADDRESS_UPDATE',
                data: account
            });
        }

    }, [account]); // eslint-disable-line react-hooks/exhaustive-deps



    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ALANI </title>
                </Helmet>
            </HelmetProvider>
            <Header />
            <Container
                className='mt-3'
                style={{
                    minWidth: 350,
                    maxWidth: 400,
                }}
            >
                {!account?.error &&
                    <NavTabs
                        section='account'
                    />
                }
                {
                    fetching === 'account_fetching' ?
                        <div
                            style={{ height: 200 }}
                            className='justify-content-center align-items-center d-flex'
                        >
                            <Spinner animation="border" />

                        </div >
                        :
                        <>
                            {
                                account.error ?
                                    <Container
                                        className='pt-5'
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >

                                        {t(account.message)}
                                        <br /><br />
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=${utils.urlencode("Mi cuenta")}`}

                                        >
                                            {utils.getWhatsappNumber(cookies.alani360)}
                                        </a>
                                    </Container>
                                    :
                                    <AccountAddress
                                        submitText={t('Actualizar')}
                                        initialValues={{
                                            ...account
                                        }}
                                        submit={({
                                            firstName,
                                            lastName,
                                            email,
                                            address1,
                                            address2,
                                            city,
                                            state,
                                            zipcode,
                                            country_code
                                        }) => {
                                            dispatch({
                                                type: 'ACCOUNT_UPDATE',
                                                payload: {
                                                    method: 'POST',
                                                    token: token,
                                                    body: {
                                                        first_name: firstName,
                                                        last_name: lastName,
                                                        email,
                                                        address1,
                                                        address2,
                                                        city,
                                                        state,
                                                        zipcode,
                                                        country_code
                                                    }
                                                }
                                            });
                                        }}
                                    />
                            }
                        </>
                }
            </Container>
        </>
    );
}

export default CustomerInfo;
