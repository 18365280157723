import Header from '@Components/Header';
import { Container } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useCookies } from 'react-cookie';

function Privacy() {

    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    return <><Header />
        <HelmetProvider>
            <Helmet>
                <title>ALANI 360 :: Privacidad</title>
            </Helmet>
        </HelmetProvider>
        <Container
            className='mt-3 center'
        >
            <div
                style={{ textAlign: 'center' }}
            >

                <h5>
                    TARIFAS {cookies.alani360}
                </h5>
                Nuestras tarifas incluyen el envío, impuestos y entrega a domicilio.

            </div>
        </Container>
    </>


}

export default Privacy;
