//input form to enter the token

import React, { useState } from 'react';

import api from '@Services/api';
import { useCookies } from 'react-cookie';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
    Spinner,
    ToastContainer,
    Toast,
} from 'react-bootstrap';
import utils from '@Components/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
const ExpiredToken = ({
    redirect,
    newToken,
    concurso,
    after_send_token,
    backgroundColor
}) => {
    let token = useParams().token;
    const { t } = useTranslation();
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars
    const [showToast, setShowToast] = useState({ bg: backgroundColor || 'success', show: false, message: '' });

    const [phone, setPhone] = useState("+" + (cookies.alani360info?.phone || utils.getCountryDialingCode(cookies.alani360).replace("+", ""))); // eslint-disable-line no-unused-vars
    const [fetching, setFetching] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState(cookies.alani360); // eslint-disable-line no-unused-vars
    const [linkSent, setLinkSent] = useState(false);

    const [message, setMessage] = useState('');
    const [activationCode, setActivationCode] = useState('');

    const [redirect2, setRedirect2] = useState(redirect);
    React.useEffect(() => {
        const currentUrl = window.location.href;
        const rootUrl = window.location.origin;
        const pathAfterRoot = currentUrl.substring(rootUrl.length);
        //remove the token from the path
        let pathWithoutToken = pathAfterRoot.replace(token, '[TOKEN]');

        // Check if [TOKEN] is already in the path
        if (!pathWithoutToken.includes('[TOKEN]')) {
            // If not, add [TOKEN] at the beginning
            const pathParts = pathWithoutToken.split('/');
            pathParts.splice(1, 0, '[TOKEN]');
            pathWithoutToken = pathParts.join('/');
        }

        setRedirect2(pathWithoutToken);
    }, []); //eslint-disable-line



    const fnReactivate = () => {


        if (
            !phone
            ||
            phone.length < 10
            ||
            phone === '+'
        ) {
            setShowToast({
                bg: 'danger',
                show: true,
                message: 'Por favor ingresa tu número de celular'
            });
            return;
        }

        let payload = {
            phone,
            country_code: selectedCountry,
            redirect: redirect2,
            concurso: (concurso || 0)
        }


        setFetching(true);


        api.reactivate({
            method: 'get',
            payload
        }).then(res => {
            setFetching(false);

            if (res.success) {

                if (after_send_token) {
                    after_send_token();
                } else {


                    setLinkSent(true);
                    if (res.request_country !== res.account_country) {
                        setMessage(`Tu cuenta está registrada en ${utils.countryName(res.account_country)} ${utils.countryFlag(res.account_country)}`);
                        setCookie('alani360', res.account_country, { path: '/' });

                    }

                    // Show Bootstrap dialog confirming token has been sent
                    setShowToast({
                        bg: backgroundColor || 'success',
                        show: true,
                        message: 'Se ha enviado un token a tu WhatsApp. Por favor revisa tu mensaje.'
                    });

                    // Optional: You can also use a Modal for a more prominent confirmation
                    // Uncomment the following lines if you prefer using a Modal
                    /*
                    setShowModal(true);
                    */

                }
            } else {
                setShowToast({ bg: 'danger', show: true, message: res.message });

            }

        });
    }


    if (linkSent) {
        return (
            <div
                className='
                d-flex flex-column justify-content-center align-items-center 
                pt-3 mt-3
                pb-3 mb-3
                '
                style={{
                    background: backgroundColor || 'transparent',
                    textAlign: 'center',

                    margin: 'auto',
                    borderRadius: 5,
                }}
            >

                <div
                    className={"text-black"}
                >
                    {message !== '' &&
                        <div style={{ padding: 5 }}   >
                            {message}
                        </div>
                    }
                    {t('Revisa tu WhatsApp')}
                    <br />
                    <div className="mt-3 mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Código"
                            style={{
                                maxWidth: '120px',
                                margin: 'auto',
                                textAlign: 'center'
                            }}
                            value={activationCode}
                            onChange={(e) => {
                                setActivationCode(e.target.value);
                            }}
                            disabled={fetching}
                        />
                        <button
                            className="btn btn-primary mt-2"
                            onClick={() => {
                                //refresh current page adding the activation code
                                if (activationCode === '') {
                                    alert('Por favor ingresa el código');
                                    return;
                                }
                                setFetching(true);
                                //call api to check if the code is valid
                                api.checkCode({
                                    payload: {
                                        code: activationCode,
                                        phone
                                    }
                                }).then(res => {
                                    setFetching(false);
                                    if (res.success) {
                                        let href = redirect2.replace('[TOKEN]', activationCode);
                                        window.location.href = href
                                    } else {
                                        alert('Código inválido, intenta de nuevo o solicita uno nuevo');
                                        return
                                    }
                                })


                            }}
                        >
                            {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                            {t('Activar')}
                        </button>
                    </div>


                    <div
                        style={{
                            marginTop: '1rem',
                            paddingTop: '1rem',
                            borderTop: '1px solid #c0c0c0',
                        }}
                    >
                        <PhoneInput
                            labels={
                                {
                                    "ZZ": "Internacional",
                                    "CO": "Colombia",
                                    "CR": "Costa Rica",
                                    "MX": "México",
                                    "US": "Estados Unidos",
                                    "CL": "Chile",
                                    "PE": "Perú",
                                    "BR": "Brasil",
                                    "DO": "República Dominicana",

                                }
                            }
                            style={{
                                fontSize: 16,
                                width: 200,
                            }}
                            countries={["MX", "DO", "US", "CO", "CR", "PE", "CL", "BR"]}
                            international
                            placeholder="Celular"
                            value={phone}
                            onChange={setPhone}
                            disabled={fetching}
                            defaultCountry={selectedCountry}
                        />
                        <button
                            className="btn btn-secondary"
                            onClick={() => {
                                setActivationCode("");
                                fnReactivate();
                            }}
                            disabled={fetching}
                            style={{ width: '180px', marginTop: '1rem' }}
                        >
                            {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                            {t('Reenviar Código')}
                        </button>
                    </div>


                </div>
            </div>
        )
    }

    return (
        <>



            <div
                className='d-flex 
                justify-content-center 
                align-items-center'

            >
                <ToastContainer
                    position='top-center'
                    style={{ marginTop: 20 }}
                    onClick={() => {
                        setShowToast({ bg: '', show: false, message: '' });
                    }}
                >
                    <Toast show={showToast.show}
                        style={{
                            maxWidth: 250,
                        }}
                        bg={showToast.bg}
                        onClose={() => {
                            setShowToast({ bg: '', show: false, message: '' });
                        }}
                    >
                        <Toast.Body
                            className='text-white text-center'
                        >
                            {showToast.message}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>





            <div
                style={{
                    background: newToken ?
                        'transparent' :
                        backgroundColor || '#F89A43',
                    textAlign: 'center',

                    margin: 'auto',
                    borderRadius: 5,

                }}
                className='d-flex flex-column justify-content-center align-items-center p-3 pt-1 mt-3'
            >

                <p>

                    {t('ingresa_numero_celular')}

                </p>



                <PhoneInput
                    labels={
                        {
                            "ZZ": "Internacional",
                            "CO": "Colombia",
                            "CR": "Costa Rica",
                            "MX": "México",
                            "US": "Estados Unidos",
                            "CL": "Chile",
                            "PE": "Perú",
                            "BR": "Brasil",
                            "DO": "República Dominicana",

                        }
                    }
                    style={{
                        fontSize: 16,
                        width: 200,
                    }}
                    countries={["MX", "DO", "US", "CO", "CR", "PE", "CL", "BR"]}
                    international
                    placeholder="Celular"
                    value={phone}
                    onChange={setPhone}
                    disabled={fetching}
                    defaultCountry={selectedCountry}
                />
                {newToken ?
                    <button
                        className="btn"
                        onClick={() =>
                            fnReactivate()
                        }
                        disabled={fetching}
                        style={{

                            marginTop: '1rem',
                            backgroundColor: '#00AE4C',
                            color: 'white',
                        }}
                    >
                        {fetching &&
                            <Spinner className='me-2' animation="border" size='sm' />

                        }
                        &nbsp; {t("continuar_en_whatsapp")}
                    </button>

                    :
                    <button
                        className="btn btn-primary"
                        onClick={() => fnReactivate()}
                        disabled={fetching}
                        style={{ width: '180px', marginTop: '1rem' }}
                    >
                        {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                        {t('Activar Sesión')}
                    </button>

                }

            </div >

        </>
    );
}
export default ExpiredToken;
