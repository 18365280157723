import React from 'react';
import { Button } from 'react-bootstrap';
import utils from '@Components/utils';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

function OfertasList({ products, showExcludedProducts, tokenIsValid, onShowModal, token }) {
    const { t } = useTranslation();
    const [cookies] = useCookies(['alani360']);

    return (
        <div className="mt-0">
            {products.map((oferta, index) => (
                <div key={index} className="card mb-3">
                    <div className="row g-0">
                        <div>
                            <div className="card-body">
                                <img src={oferta.image} className="p-2 img-fluid rounded-start" style={{ maxWidth: 100, float: 'left', marginRight: '15px' }} alt={oferta.name} />

                                <h5 className="card-title">{oferta.name}</h5>

                                <div className="card-text description-container">
                                    <p
                                        className="description"
                                        style={{
                                            maxHeight: '100px',
                                            overflow: 'hidden',
                                            position: 'relative',
                                            marginBottom: 0,
                                            transition: 'max-height 0.3s ease-out'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: oferta.longDescription }}
                                    />

                                    <span
                                        style={{
                                            fontSize: 14,
                                            cursor: 'pointer'
                                        }}
                                        className="btn btn-link"
                                        onClick={(e) => {
                                            const desc = e.target.previousElementSibling;
                                            if (desc.style.maxHeight === '100px') {
                                                desc.style.maxHeight = '2000px';
                                                e.target.textContent = t('Ver menos');
                                            } else {
                                                desc.style.maxHeight = '100px';
                                                e.target.textContent = t('Ver más');
                                            }
                                        }}
                                    >
                                        {t('Ver más')}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between align-items-end mt-2">
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}>
                                        {oferta.allInPriceRegular &&


                                            (oferta.allInPriceRegular - oferta.allInPrice > 40)

                                            &&
                                            (
                                                <span style={{
                                                    textDecoration: 'line-through',
                                                    marginBottom: '5px'
                                                }}>
                                                    {utils.formatCurrency(oferta.allInPriceRegular, oferta.currency_code)}
                                                </span>
                                            )}

                                        <Button
                                            style={{
                                                fontSize: 15,
                                                textAlign: 'center',
                                                padding: '5px 20px',
                                                borderRadius: 5,
                                                backgroundColor: ((!oferta.onlineAvailability || oferta.excluded) && showExcludedProducts === 0) ? '#dc3545' : '#28a745',
                                                color: 'white',
                                                border: 'none',
                                            }}
                                            onClick={() => {
                                                if (tokenIsValid) {
                                                    window.open(`/b/${token}/${oferta.sku}?r=1`, '_blank');
                                                } else {
                                                    onShowModal();
                                                }
                                            }}
                                            disabled={(!oferta.onlineAvailability || oferta.excluded) && showExcludedProducts === 0}
                                        >
                                            {utils.formatCurrency(oferta.allInPrice, oferta.currency_code)}
                                        </Button>

                                        <div style={{ fontWeight: 600, marginTop: 10, fontSize: 14, textAlign: 'center', width: '100%' }}>
                                            {t('Incluye costos de importación y envío')}
                                            <br />
                                            a {utils.countryName(cookies.alani360)}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default OfertasList;
