import React from 'react';
import { useNavigate } from 'react-router-dom';

const FeaturedProduct = ({ name, description, imageUrl, url, imageStyle }) => {
    const navigate = useNavigate();

    return (
        <>
            <div
                className='mt-3'
                style={{
                    maxWidth: 350,
                    margin: 'auto',
                }}
                dangerouslySetInnerHTML={{ __html: description }}
            >
            </div>
            <div
                className='mt-3'
                style={{
                    maxWidth: 350,
                    margin: 'auto',
                }}
            >
                <img
                    src={imageUrl}
                    className="p-2 img-fluid rounded-start"
                    alt={name}
                    style={
                        imageStyle ||
                        {
                            maxWidth: '100px',
                            float: 'left',
                            marginRight: '15px'
                        }}
                />
                <div
                    dangerouslySetInnerHTML={{ __html: name }}
                />

                <div className="d-flex justify-content-between align-items-end mt-2">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}
                        className='mt-2'
                    >
                        <button
                            type="button"
                            className="btn btn-primary  btn btn-primary"
                            style={{
                                width: '130px',
                                fontSize: '15px',
                                padding: '5px 10px'
                            }}
                            onClick={() => navigate(url)}
                        >
                            Detalles
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeaturedProduct; 