'use client';

class AlaniAnalytics {
    //get location hostname in class
    static #getIsLocalhost() {
        return typeof window !== 'undefined' && window.location.hostname === 'localhost';
    }
    static async send(additionalData = {}) {
        if (this.#getIsLocalhost()) {
            console.warn("not sending analytics localhost");
            return;
        }
        try {

            const isMobile = /Mobi|Android/i.test(navigator.userAgent);
            let url = window.location.href;
            let token = additionalData.token;
            url = url.replace(`token=${token}`, '');
            if (token) {
                url = url.replace(`/${token}`, '');
            }
            const analyticsData = {
                userAgent: navigator.userAgent,
                language: navigator.language,
                deviceType: isMobile ? 'Mobile' : 'Desktop',
                url: url,
                ...additionalData,
            };


            const queryString = Object.keys(analyticsData)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(analyticsData[key])}`)
                .join('&');



            await fetch(`https://h4gqwxd22m.execute-api.us-east-1.amazonaws.com/beta/alani_analytics/www?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }

            }).then(response => {
                //console.log('aaok!');
            }).catch(error => {
                console.error('Failed to send analytics:', error);
            });


        } catch (error) {
            console.error('Failed to send analytics:', error);
        }
    }
}

export default AlaniAnalytics;
