import { combineReducers } from 'redux';

let dataState = {
    fetching: false,
    product: {},
    account: {},
    billing_address: {},
}

const dataReducer = (state = dataState, action) => {
    switch (action.type) {
        case 'BILLING_ADDRESS_UPDATE':
            return { ...state, billing_address: action.data.billing_address };

        case 'API_RESULT_SET':
            return { ...state, api_result: action.result };
        case 'FETCHING_SET':
            return { ...state, fetching: action.fetching };
        case 'ORDER_SET':
            return { ...state, order: action.data };


        case 'ACCOUNT_SET':

            if (action.data?.data?.account) {
                action.data.account = action.data?.data?.account;
                action.data.orders = action.data?.data?.orders;
            }
            if (!action.data.account) {
                return { ...state };
            }
            return {
                ...state,
                account: action.data.account,
                orders: action.data.orders,
            };

        case 'PRODUCT_CLEAR':
            return { ...state, product: {}, also_viewed: null, open_box: null };
        case 'PRODUCT_SET':
            return {
                ...state,
                product: action.data.product,
                also_viewed: action.data.alsoViewed,
                open_box: action.data.open_box
            };

        case 'CART_SET':
            /*if (!action.data.cart) {
                return { ...state };
            }*/
            return { ...state, cart: action.data.cart };
        case 'CART_ITEMS_SET':
            /*if (!action.data.cart_items) {
                return { ...state };
            }*/
            return { ...state, cart_items: action.data.cart_items };



        case 'ORDER_CLEAR':
            return {
                ...state,
                order: null
            };

        case 'ADDRESS_UPDATE':
            return {
                ...state,
                address_data: action.data,
                address_update: new Date().getTime()
            };

        case 'ADDRESS_REMEMBER':
            return {
                ...state,
                address_data: action.data
            };
        case 'ADDRESS_ERRORS_REMEMBER':
            return {
                ...state,
                address_errors_data: action.data
            };
        default:
            return state;
    }

}


const rootReducer = combineReducers({
    dataReducer,
})


export default rootReducer;