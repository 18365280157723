import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

import { CookiesProvider, useCookies } from 'react-cookie';

import ReactGA from "react-ga4";

import './i18n';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useParams
} from "react-router-dom";

import { Provider } from 'react-redux';
import store from './app/store'

import Product from '@Screens/Product';
import CustomerInfo from '@Screens/CustomerInfo';
import ShippingSreen from '@Screens/ShippingScreen';
import PaymentScreen from '@Screens/PaymentScreen';
import CartScreen from '@Screens/CartScreen';
import OrdersScreen from '@Screens/OrdersScreen';
import OrderDetails from '@Screens/Order';
import SearchResults from '@Screens/SearchResults';
import Country from '@Screens/Country';
import Search from '@Screens/Search';

import Tarifas from '@Screens/Tarifas';
import Ofertas from '@Screens/public/Ofertas';
import OfertasLandingPage from '@Screens/public/OfertasLandingPage';

import SearchWapResults from '@Screens/public/SearchWapResults';

import AlaniAnalytics from '@Components/AlaniAnalytics';

ReactGA.initialize("G-3XDRV10LNW"); // Replace with your actual GA4 measurement ID

function GATracker() {
  const location = useLocation();
  const [cookies] = useCookies(['alani360info']);
  const [cookieCountry] = useCookies(['alani360']);
  const params = useParams();  // Add this line
  useEffect(() => {
    const userId = cookies.alani360info?.id || 'anonymous';
    const phone = cookies.alani360info?.phone || 'unknown';
    const country = cookieCountry.alani360 || 'unknown';
    const token = params.token || '';  // Add this line

    AlaniAnalytics.send({
      userId: userId,
      phone: phone,
      country: country,
      event: "pageview",
      token: token,
    });

    // dont send on localhost or 192.168.x.x
    if (window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.')) {
      return;
    }



    ReactGA.set({ userId: userId });
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,

    });
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

const Home = React.lazy(() => import('@Screens/public/Home'));
const BBY = React.lazy(() => import('@Screens/public/BBY'));
const Privacy = React.lazy(() => import('@Screens/public/Privacy'));
const Brands = React.lazy(() => import('@Screens/public/Brands'));
const Terms = React.lazy(() => import('@Screens/public/Terms'));
const Restrictions = React.lazy(() => import('@Screens/public/Restrictions'));

const Contest = React.lazy(() => import('@Screens/public/Contest'));


const Ayuda = React.lazy(() => import('@Screens/public/Ayuda'));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <Provider store={store}>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
      >
        <Suspense fallback={<div
          className='d-flex flex-column justify-content-center align-items-center p-5'
          style={{ textAlign: 'center' }}>

          Best Buy powered by ALANI 360...


          <img
            style={{ width: 100, opacity: 0.5 }}
            className='mt-5'
            src='https://bby.alani360.com/images/home/bestbuy-logo-100.jpg' alt='best buy logo'
          />
        </div>}>

          <BrowserRouter>
            <Routes>
              <Route path='/concurso'
                element={
                  <>
                    <GATracker />
                    <Contest />
                  </>
                }
              />

              <Route path='/concurso/:token/reglas'
                element={
                  <>
                    <GATracker />
                    <Contest
                      reglas={true}
                    />
                  </>
                }
              />
              <Route path='/concurso/:token/ok'
                element={
                  <>
                    <GATracker />
                    <Contest
                      ok={true}
                    />
                  </>
                }
              />

              <Route path='/w/'
                element={
                  <>
                    <GATracker />
                    <SearchWapResults />
                  </>
                }
              />
              <Route path='/:token/w/'
                element={
                  <>
                    <GATracker />
                    <SearchWapResults />
                  </>
                }
              />

              <Route path='/concurso/:token'
                element={
                  <>
                    <GATracker />
                    <Contest />
                  </>
                }
              />

              <Route path='/country'
                element={
                  <>
                    <GATracker />
                    <Country />
                  </>
                }
              />


              <Route path='/'
                element={
                  <>
                    <GATracker />
                    <Home />
                  </>
                }
              />



              <Route path='/home/:token'
                element={
                  <>
                    <GATracker />
                    <Home />
                  </>
                }
              />
              <Route path='/home'
                element={
                  <>
                    <GATracker />
                    <Home />
                  </>
                }
              />
              <Route path='/bby'
                element={
                  <>
                    <GATracker />
                    <BBY />
                  </>
                }
              />
              <Route path='/:token/privacy'
                element={
                  <>
                    <GATracker />
                    <Privacy />
                  </>
                }
              />
              <Route path='/privacy'
                element={
                  <>
                    <GATracker />
                    <Privacy />
                  </>
                }
              />
              <Route path='/brands'
                element={
                  <>
                    <GATracker />
                    <Brands />
                  </>
                }
              />
              <Route path='/marcas'
                element={
                  <>
                    <GATracker />
                    <Brands />
                  </>
                }
              />
              <Route path='/terms'
                element={
                  <>
                    <GATracker />
                    <Terms />
                  </>
                }
              />
              <Route path='/restricciones'
                element={
                  <>
                    <GATracker />
                    <Restrictions />
                  </>
                }
              />
              <Route path='/consideraciones'
                element={
                  <>
                    <GATracker />
                    <Restrictions />
                  </>
                }
              />


              <Route path='/search'
                element={
                  <>
                    <GATracker />
                    <Search />
                  </>
                }
              />

              <Route path='/buscar'
                element={
                  <>
                    <GATracker />
                    <Search />
                  </>
                }
              />






              <Route path='/:token/ofertas/:offer_code'
                element={
                  <>
                    <GATracker />
                    <Ofertas />
                  </>
                }
              />

              <Route path='/ofertas/:offer_code'
                element={
                  <>
                    <GATracker />
                    <Ofertas />
                  </>
                }
              />

              <Route path='/:token/ofertas/'
                element={
                  <>
                    <GATracker />
                    <OfertasLandingPage />
                  </>
                }
              />

              <Route path='/ofertas/'
                element={
                  <>
                    <GATracker />
                    <OfertasLandingPage />
                  </>
                }
              />










              <Route path='/:token/ayuda/'
                element={
                  <>
                    <GATracker />
                    <Ayuda
                      topic={'general'}
                    />
                  </>
                }
              />
              <Route path='/ayuda/'
                element={
                  <>
                    <GATracker />
                    <Ayuda
                      topic={'general'}
                    />
                  </>
                }
              />

              <Route path='/:token/ayuda/buscar'
                element={
                  <>
                    <GATracker />
                    <Ayuda
                      topic={'buscar'}
                    />
                  </>
                }
              />
              <Route path='/ayuda/buscar'
                element={
                  <>
                    <GATracker />
                    <Ayuda
                      topic={'buscar'}
                    />
                  </>
                }
              />

              <Route path='/:token/buscar'
                element={
                  <>
                    <GATracker />
                    <Search />
                  </>
                }
              />

              <Route path='/:token/search'
                element={
                  <>
                    <GATracker />
                    <Search />
                  </>
                }
              />


              <Route path='/:token/bby'
                element={
                  <>
                    <GATracker />
                    <BBY />
                  </>
                }
              />
              <Route path='/:token/terms'
                element={
                  <>
                    <GATracker />
                    <Terms />
                  </>
                }
              />

              <Route path='/:token/tarifas'
                element={
                  <>
                    <GATracker />
                    <Tarifas />
                  </>
                }
              />

              <Route path='/:token/restricciones'
                element={
                  <>
                    <GATracker />
                    <Restrictions />
                  </>
                }
              />
              <Route path='/:token/consideraciones'
                element={
                  <>
                    <GATracker />
                    <Restrictions />
                  </>
                }
              />

              <Route path='/:token/sku/:sku'
                element={
                  <>
                    <GATracker />
                    <Product />
                  </>
                }
              />

              <Route path='/b/:token/:sku'
                element={
                  <>
                    <GATracker />
                    <Product />
                  </>
                }
              />

              <Route path='/:token/brands'
                element={
                  <>
                    <GATracker />
                    <Brands />
                  </>
                }
              />
              <Route path='/catalogo'
                element={
                  <>
                    <GATracker />
                    <Brands />
                  </>
                }
              />
              <Route path='/:token/catalogo'
                element={
                  <>
                    <GATracker />
                    <Brands />
                  </>
                }
              />
              <Route path='/:token/search/:search_code'
                element={
                  <>
                    <GATracker />
                    <SearchResults />
                  </>
                }
              />

              <Route path='/:token/marcas'
                element={
                  <>
                    <GATracker />
                    <Brands />
                  </>
                }
              />
              <Route path='/:token/account/'
                element={
                  <>
                    <GATracker />
                    <CustomerInfo />
                  </>
                }
              />
              <Route path='/:token/Shipping'
                element={
                  <>
                    <GATracker />
                    <ShippingSreen />
                  </>
                }
              />

              <Route path='/:token/cart'
                element={
                  <>
                    <GATracker />
                    <CartScreen />
                  </>
                }
              />
              <Route path='/:token/pay'
                element={
                  <>
                    <GATracker />
                    <PaymentScreen />
                  </>
                }
              />
              <Route path='/o/:token'
                element={
                  <>
                    <GATracker />
                    <OrdersScreen />
                  </>
                }
              />
              <Route path='/:token/orders'
                element={
                  <>
                    <GATracker />
                    <OrdersScreen />
                  </>
                }
              />


              <Route path='/:token/o/:order_id'
                element={
                  <>
                    <GATracker />
                    <OrderDetails />
                  </>
                }
              />
              <Route path='/:token/orders/:order_id'
                element={
                  <>
                    <GATracker />
                    <OrderDetails />
                  </>
                }
              />
              <Route path='/o/:token/:order_id'
                element={
                  <>
                    <GATracker />
                    <OrderDetails />
                  </>
                }
              />

              <Route path='/:token/'
                element={
                  <>
                    <GATracker />
                    <Home />
                  </>
                }
              />

              <Route path='/:token/concurso/'
                element={
                  <>
                    <GATracker />
                    <Contest />
                  </>
                }
              />

              <Route path='/:token/home'
                element={
                  <>
                    <GATracker />
                    <Home />
                  </>
                }
              />

              <Route
                path="*"
                element={
                  <>
                    <GATracker />
                    <App />
                  </>
                }
              />

            </Routes>
          </BrowserRouter>
        </Suspense>

      </ThemeProvider>
    </Provider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
