
import {
    Container,
    Spinner,
} from 'react-bootstrap';

import { useTranslation, } from 'react-i18next';

import { useCookies } from 'react-cookie';

import ExpiredToken from '@Components/ExpiredToken';


const FetchingComponent = (props) => {

    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    const { t } = useTranslation();




    return <>{props.fetching === 'cart_fetching' ?
        <Container
            className='justify-content-center align-items-center d-flex mt-5'

        >
            <Spinner animation="border" />
        </Container>
        :
        props.api_result?.error ?
            <Container
                className='mt-5'
                style={{
                    minWidth: 300,
                    maxWidth: 400,
                    textAlign: 'center',
                }}
            >
                {/*t(props.api_result?.message)*/}
                <div
                    className='mt-3'
                >

                    {
                        props.api_result.message === 'invalid_token' &&
                        <>
                            <h4>
                                {t(props.custom_message)}
                            </h4>
                            <ExpiredToken
                                redirect={`/[TOKEN]/pay`}
                            />
                        </>
                    }

                </div>
            </Container>
            :
            <>
                {props.children}

            </>


    }

        {
            props?.api_result?.message === 'no_open_shopping_carts' &&
            <Container
                className='mt-5'
                style={{
                    minWidth: 300,
                    maxWidth: 400,
                    textAlign: 'center',
                }}
            >
                {t('Tu carrito está vacío')}
            </Container>
        }

    </>
}

export default FetchingComponent;
