import React, { useState } from 'react';
import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container, Spinner,
    Card,
    Form,
    Toast,
    ToastContainer,
    Tab,
    Tabs,
    Button
} from 'react-bootstrap';

import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import ExpiredToken from '@Components/ExpiredToken';
import utils from '@Components/utils';
import api from '@Services/api';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';
import 'moment/locale/es';
moment.tz.setDefault("America/Mexico");


const Results = ({ results }) => {
    const { t } = useTranslation();
    let token = useParams().token;
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars
    return <>

        <div
            className="d-flex flex-row justify-content-center align-items-center flex-wrap"
        >

            {
                results.map((result, index) => {
                    if (!result.onlineAvailability) return null;
                    return <Card
                        key={index}
                        className='mt-3'
                        style={{
                            width: "100%",
                        }}
                    >


                        <Card.Body>
                            <Card.Text  ><b>{result.name}</b></Card.Text>
                            <Card.Text  >
                                <img src={result.image} className="p-2 img-fluid rounded-start" style={{ maxWidth: 150, float: 'left', marginRight: '15px' }} alt={result.name} />

                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: result.longDescription
                                    }}
                                ></div>
                            </Card.Text>
                            <Card.Text>
                                {
                                    !!result.onSale &&
                                    <span
                                        className='badge bg-danger'
                                    >
                                        {t('En Oferta')}
                                    </span>
                                }
                            </Card.Text>

                            <div className='d-flex justify-content-center align-items-center'>
                                <a
                                    rel="noreferrer"
                                    target='_blank'
                                    href={`/b/${token}/${result.sku}?r=1`}
                                    className="btn btn-primary"
                                    style={{
                                        width: '200px',
                                        margin: 'auto'
                                    }}
                                >
                                    {result.allInPrice
                                        ?
                                        utils.formatCurrency(result.allInPrice, result.currency_code)
                                        :
                                        "N/A"
                                    }
                                </a>
                            </div>

                            <div style={{ fontWeight: 600, marginTop: 10, fontSize: 14, textAlign: 'center', width: '100%' }}>
                                {t('Incluye costos de importación y envío')}
                                <br />
                                a {utils.countryName(cookies.alani360)}

                            </div>
                        </Card.Body>
                    </Card>
                }
                )
            }
        </div >
    </>
}


const SearchComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate();
    let token = useParams().token;

    const { t } = useTranslation();

    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars


    const [searchValue, setSearchValue] = React.useState(searchParams.get('s') || '');
    const [sku, setSKU] = React.useState('');
    const [onSale, setOnSale] = React.useState(false);
    const [refurbished, setRefurbished] = React.useState(false);
    const [newProduct, setNewProduct] = React.useState(false);

    const [fetching, setFetching] = React.useState(false);
    const [results, setResults] = React.useState([]);

    const [noResults, setNoResults] = useState(false);

    const bParam = searchParams.get('b');
    const [key, setKey] = useState('home');




    //if query param s is present on load, file handleSearch
    React.useEffect(() => {
        if (searchParams.get('s')) {
            fnSearch({
                search: searchParams.get('s')
            });
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const fnSearch = ({
        sku,
        search
    }) => {

        let body

        if (sku) {
            body = {
                sku,
                country_code: cookies.alani360,
                phone: '0'
            }
        } else {
            if (!searchValue) {
                setShowToast(true);
                return;
            }
            body = {
                description: searchValue,
                country_code: cookies.alani360,
                on_sale: onSale ? 1 : 0,
                refurbished: refurbished ? 1 : 0,
                newProduct: newProduct ? 1 : 0,
                phone: '0'
            }
        }






        setNoResults(false);
        setFetching(true);
        setResults([])
        api.search({
            method: 'POST',
            payload: {
                body
            }
        }).then(res => {

            setFetching(false);
            //console.warn(res);

            if (res.error) {
                if (res.products.length === 0) {
                    setNoResults(true);
                }
                return;
            }
            if (res.results.length === 0) {
                setNoResults(true);
            }
            setResults(res.results);

        });
    }




    return (<>
        <ToastContainer
            position='middle-center'
            onClick={() => {
                setShowToast(false);
            }}
        >
            <Toast show={showToast}
                style={{
                    maxWidth: 350,
                }}
                bg='danger'
                onClose={() => {
                    setShowToast(false);
                }} delay={3000} autohide
            >
                <Toast.Body
                    className='text-white text-center'
                >
                    {t('Debes ingresar una descripción')}
                </Toast.Body>
            </Toast>
        </ToastContainer>

        <Button
            className='mb-3'
            variant='success'
            size='sm'
            onClick={() => navigate(
                bParam ?
                    bParam
                    :
                    !!token ? `/${token}/ofertas/` : '/ofertas/')
            }
        >
            {t('Ofertas')}
        </Button >
        <Tabs
            variant='tabs'
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
                setNoResults(false);
                setKey(k);

            }}
            className="mb-3"
        >
            <Tab eventKey="home" title={t('Descripción')} >
                <div
                    className="d-flex flex-column justify-content-center align-items-center "
                >
                    <div
                        className="d-flex flex-row justify-content-center align-items-center flex-wrap"
                    >

                        <div>

                            <input
                                disabled={fetching}
                                className="m-1 form-control"
                                type="text"
                                placeholder={t('Descripción')}
                                value={searchValue}
                                autoFocus
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        fnSearch({
                                            search: searchValue
                                        });
                                    }
                                }}
                                onChange={(e) => {
                                    setSearchValue(e.target.value)
                                }}

                                style={{ width: "350px", }}
                            />
                        </div>



                    </div>
                    <div>
                        <div>
                            <Form.Check
                                type={`switch`}
                                id={`on_sale`}
                                label={t('En Oferta')}
                                checked={onSale}
                                onChange={() => setOnSale(!onSale)}
                                disabled={fetching}
                            />


                            <Form.Check
                                type={`switch`}
                                id={`refurbished`}
                                label={t('Reacondicionado')}
                                checked={refurbished}
                                disabled={fetching}
                                onChange={() => {
                                    setRefurbished(!refurbished);
                                    if (!refurbished) {
                                        setNewProduct(false);
                                    }
                                }}
                            />

                            <Form.Check
                                type={`switch`}
                                id={`newProduct`}
                                label={t('Nuevo')}
                                checked={newProduct}
                                disabled={fetching}
                                onChange={() => {
                                    setNewProduct(!newProduct)
                                    if (!newProduct) {
                                        setRefurbished(false);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={() => fnSearch({})}
                        disabled={fetching}
                        style={{ width: '120px', marginTop: '1rem' }}
                    >
                        {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                        {t('Buscar')}
                    </button>
                </div>

            </Tab>
            <Tab eventKey="profile" title="SKU">
                <div
                    className="d-flex flex-column justify-content-center align-items-center "
                >
                    <div>
                        <label
                            htmlFor="search"
                        >
                            SKU
                        </label>
                        <input
                            disabled={fetching}
                            className="m-1 form-control"
                            type="text"
                            placeholder="SKU"
                            value={sku}
                            autoFocus
                            onKeyDown={(e) => {
                                if (
                                    e.key !== 'Backspace'
                                    &&
                                    e.key !== 'v'

                                    &&
                                    !/[0-9]/.test(e.key)

                                ) {
                                    e.preventDefault();
                                }
                                if (e.key === 'Enter') {
                                    fnSearch({
                                        sku
                                    });
                                    //set focus on this field
                                    e.target.focus();

                                }
                            }}
                            onChange={(e) => {
                                //replace all nonnumeric values
                                let val = e.target.value.replace(/\D/g, '');
                                setSKU(val)

                            }}


                            style={{ width: '300px' }}
                        />
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={() => fnSearch({
                            sku
                        })}
                        disabled={fetching}
                        style={{ width: '120px', marginTop: '1rem' }}
                    >
                        {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                        Buscar
                    </button>
                </div>
            </Tab>


        </Tabs>

        {
            noResults ?
                <div
                    className='d-flex justify-content-center pt-5'

                >

                    <b>🙁 No se encontraron resultados</b>
                </div>
                :
                <Results results={results} />
        }

    </>
    )
}

function SearchScreen() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    let token = useParams().token;


    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        fetching
    } = dataReducer;


    React.useEffect(() => {
        dispatch({
            type: 'ACCOUNT_FETCH',
            payload: {
                token,
            }
        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <HelmetProvider>
            <Helmet>
                <title>ALANI Search</title>
            </Helmet>
        </HelmetProvider>
        {!!token ?
            <HeaderAccount />
            :
            <Header />
        }
        <Container
            className='container-main mt-3 mb-5'
        >
            {fetching === 'account_fetching' ?
                <div
                    className="mt-5 d-flex justify-content-center align-items-center"
                >
                    <Spinner animation="border" />
                </div>
                :
                <div
                    style={{ margin: 'auto' }}
                >
                    {account.error ?
                        <>
                            <ExpiredToken
                                redirect={`/[TOKEN]/buscar`}
                            />

                        </>

                        :
                        <SearchComponent />
                    }
                </div>
            }

        </Container>


    </>
}

export default SearchScreen;
