
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';


import api from '@Services/api';

function* fn_orderFetch(action) {
    try {
        //let phone = yield select(getPhone);

        yield put({ type: "FETCHING_SET", fetching: 'order' });
        const data = yield call(api.order_fetch, action);
        yield put({ type: "ORDER_SET", data });
        yield put({ type: "FETCHING_SET", fetching: false });


    } catch (e) {
        //console.warn(e)
        yield put({ type: "fn_usersFetch", message: e.message });
    }
}
function* fn_productFetchBySkuToken(action) {
    try {
        //let phone = yield select(getPhone);
        yield put({ type: "FETCHING_SET", fetching: 'product_fetching' });
        const data = yield call(api.product_fetch, action);
        yield put({ type: "ACCOUNT_SET", data });
        yield put({ type: "PRODUCT_SET", data });
        yield put({ type: "FETCHING_SET", fetching: false });


    } catch (e) {
        //console.warn(e)
        yield put({ type: "fn_productFetchBySkuToken", message: e.message });
    }
}


function* fn_accountUpdate(action) {
    try {
        //let phone = yield select(getPhone); 
        yield put({ type: "FETCHING_SET", fetching: 'account_saving' });
        const data = yield call(api.account.operation, action);
        yield put({ type: "ACCOUNT_SET", data });
        yield put({ type: "FETCHING_SET", fetching: false });

    } catch (e) {
        //console.warn(e)
        yield put({ type: "fn_accountUpdate", message: e.message });
    }
}


function* fn_accountFetch(action) {

    try {
        //let phone = yield select(getPhone);

        yield put({ type: "FETCHING_SET", fetching: 'account_fetching' });
        const data = yield call(api.account.operation, action);
        yield put({ type: "ACCOUNT_SET", data });
        yield put({ type: "FETCHING_SET", fetching: false });


    } catch (e) {
        //console.warn(e)
        yield put({ type: "fn_accountFetch", message: e.message });
    }
}

function* fnCart(action) {
    try {
        //let phone = yield select(getPhone); 
        yield put({ type: "FETCHING_SET", fetching: action.fetching || 'cart_fetching' });
        const data = yield call(api.cart.operation, action);

        yield put({ type: "ACCOUNT_SET", data });
        yield put({ type: "CART_SET", data });
        yield put({ type: "CART_ITEMS_SET", data });

        yield put({
            type: "FETCHING_SET",
            fetching: action.fetching_done || false
        });

        yield put({
            type: "API_RESULT_SET",
            result: data.result
        });


    } catch (e) {
        //console.warn(e)
        yield put({ type: "fnCart", message: e.message });
    }
}

function* fnCartPay(action) {
    try {
        //let phone = yield select(getPhone); 
        yield put({ type: "FETCHING_SET", fetching: action.fetching || 'cart_fetching' });
        const data = yield call(api.cart.operation, action);


        yield put({
            type: "FETCHING_SET",
            fetching: action.fetching_done || false
        });

        yield put({
            type: "API_RESULT_SET",
            result: data.result
        });


    } catch (e) {
        //console.warn(e)
        yield put({ type: "fnCartPay", message: e.message });
    }
}

export default function* rootSaga() {


    yield takeLatest('PRODUCT_FETCH_BY_SKU_TOKEN', fn_productFetchBySkuToken);
    yield takeLatest('ORDER_FETCH', fn_orderFetch);

    yield takeLatest('ACCOUNT_FETCH', fn_accountFetch);
    yield takeEvery('ACCOUNT_UPDATE', fn_accountUpdate);

    yield takeLatest('CART_OPERATION', fnCart);

    yield takeLatest('CART_PAY', fnCartPay);
}

